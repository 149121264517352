
  import {Component, Vue} from 'vue-property-decorator';
  import MaterialProgress from '@/views/material/MaterialProgress.vue';
  import api from '@/infrastructure/api/API';
  import {progress} from '@/infrastructure/script/Progress';
  import {EmptyMaterial, Material, MaterialGetRequest} from '@/types/material/Material';
  import ContentProgress from '@/views/material/ContentProgress.vue';

  @Component({
    components: {ContentProgress, MaterialProgress},
  })
  export default class MaterialDetail extends Vue {
    // data
    material: Material = EmptyMaterial;
    request: MaterialGetRequest | null = null;

    // methods
    async initialize(): Promise<void> {
      await this.getMaterial();
    }

    async getMaterial(): Promise<void> {
      const getMaterial = async (): Promise<void> => {
        if (!this.request) {
          return;
        }
        await api.getMaterial(this.request)
          .then((response: any) => {
            this.material = response;
          });
      };

      await progress(getMaterial);
    }

    toMyPage(): void {
      this.$router.push({path: '/'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    // lifecycle hooks
    created(): void {
      this.request = {
        // @ts-ignore
        materialId: this.$route.params.id as number,
      };
      this.initialize();
    }
  }
